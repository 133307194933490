form {
  max-width: 500px;
  padding: 10px 20px;
  background: #f4f7f8;
  margin: 10px auto;
  padding: 20px;
  background: #f4f7f8;
  border-radius: 4px;
}
fieldset {
  border: none;
}
legend {
  font-size: 1.4em;
  margin-bottom: 10px;
}
label {
  display: block;
  font-weight: bold;
  margin: 8px 0;
}
input[type='text'],
input[type='date'],
input[type='password'],
input[type='datetime'],
input[type='email'],
input[type='number'],
input[type='search'],
input[type='time'],
input[type='url'],
textarea,
select {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 4px;
  font-size: 15px;
  margin: 5px 0;
  outline: 0;
  padding: 10px;
  font-weight: normal;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  background-color: #e8eeef;
  color: #8a97a0;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
}
input[type='text']:focus,
input[type='date']:focus,
input[type='datetime']:focus,
input[type='email']:focus,
input[type='number']:focus,
input[type='search']:focus,
input[type='time']:focus,
input[type='url']:focus,
textarea:focus,
select:focus {
  background: #d2d9dd;
}
select {
  -webkit-appearance: menulist-button;
  height: 35px;
}
.number {
  background: #1abc9c;
  color: #fff;
  height: 30px;
  width: 30px;
  display: inline-block;
  font-size: 0.8em;
  margin-right: 4px;
  line-height: 30px;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  border-radius: 15px 15px 15px 0px;
}

input[type='submit'],
input[type='button'] {
  position: relative;
  display: block;
  padding: 19px 39px 18px 39px;
  color: #fff;
  margin: 0 auto;
  background: #1abc9c;
  font-size: 18px;
  text-align: center;
  font-style: normal;
  width: 100%;
  border: 1px solid #16a085;
  border-width: 1px 1px 3px;
  margin-bottom: 10px;
}
input[type='submit']:hover,
input[type='button']:hover {
  background: #109177;
}

input[type='range'] {
  -webkit-appearance: none;
  width: calc(100% - (73px));
  height: 6px;
  border-radius: 2px;
  background: #d7dcdf;
  outline: none;
  padding: 0;
  margin: 0;
}

/* custom thumb */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #1abc9c;
  cursor: pointer;
  -webkit-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
}

input[type='range']::-webkit-slider-thumb:hover {
  background: #1abc9c;
}

input[type='range']:active::-webkit-slider-thumb {
  background: #1abc9c;
}

input[type='range']::-moz-range-thumb {
  width: 18px;
  height: 18px;
  border: 0;
  border-radius: 50%;
  background: #1abc9c;
  cursor: pointer;
  -webkit-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
}

input[type='range']::-moz-range-thumb:hover {
  background: #1abc9c;
}

input[type='range']:active::-moz-range-thumb {
  background: #1abc9c;
}
