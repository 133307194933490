html {
  overflow-y: hidden;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Muli', cursive !important;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 30px;
  height: 30px;
}

.leaflet-container a.leaflet-popup-close-button {
  top: 4px;
  right: 4px;
  padding: 0;
  font-size: 26px;
}
